import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import configSlice from './configSlice';
import uiSlice from './uiSlice';

const reducers = combineReducers({
  data: configSlice,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['storage'],
};
const persistedReducer = persistReducer(persistConfig, reducers);


const makePersistConfig = (key: string) => ({
  key,
  storage: storage,
})

const rootReducer = combineReducers({
  ui: persistReducer(makePersistConfig('ui'), uiSlice),
  config: persistReducer(makePersistConfig('config'), configSlice),
  // config: configSlice
})

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      // thunk: true,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// export const { selectProduct } = data.actions;
// export const { addCart, removeCart } = storageSlice.actions;

// MAKE = useSelector(selectStorage);
export const selectConfig = (state: RootState) => state.config;

// export { useLocalStorage };
export default store;
