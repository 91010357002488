import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { Box, InputAdornment, Stack, Switch, Typography, TextField } from '@mui/material';
import NumberFormat from 'react-number-format';
import Avatar from '@mui/material/Avatar';
import Layout from '../templates/Layout';
import { useAppDispatch, useAppSelector } from '../../config/redux/hooks';
import { setPecahan, editPecahan } from '../../config/redux/configSlice';
import InputField from '../molecules/InputField';
import type { StateFormatNumber } from '../../types';

const Settings = () => {
	const dispatch = useAppDispatch();
	const { pecahan } = useAppSelector((state) => state.config);
	const totalThr = Object.values(pecahan)
		.filter((item) => item.enabled === true)
		.reduce(
			(previousValue, currentValue) => previousValue + Number(currentValue.total) * Number(currentValue.value),
			0
		);

	return (
		<Layout>
			{Object.values(pecahan).map((item) => (
				<Box
					key={item.id}
					sx={{
						bgcolor: 'background.paper',
						p: 2,
						mb: 1,
						borderRadius: 2,
					}}
				>
					<Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between' sx={{ mt: 1 }}>
						<Typography variant='h6' component='div' color='text.primary'>
							{item.name}
						</Typography>
						<Switch
							color='success'
							checked={item.enabled}
							onChange={(event) => {
								dispatch(
									editPecahan({
										id: item.id,
										value: event.target.checked,
									})
								);
							}}
						/>
					</Stack>
					<InputField
						value={item.total}
						// label={`Total`}
						sideValue={'lembar'}
						onChange={(event: StateFormatNumber) => {
							console.log(event);
							dispatch(
								setPecahan({
									id: item.id,
									value: { ...item, total: event.value },
								})
							);
						}}
						decimalScale={0}
						disabled={!item.enabled}
						helperText={
							<NumberFormat
								value={Number(item.total) * Number(item.value)}
								isNumericString
								thousandSeparator='.'
								decimalSeparator=','
								decimalScale={0}
								displayType={'text'}
								prefix='Rp. '
							/>
						}
					/>
				</Box>
			))}

			<Box
				sx={{
					bgcolor: 'background.paper',
					p: 2,
					mb: 1,
					borderRadius: 2,
				}}
			>
				<Typography variant='h6' component='div' color='text.primary'>
					{`Total : `}
					<NumberFormat
						value={totalThr}
						isNumericString
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={0}
						displayType={'text'}
						prefix='Rp. '
					/>
				</Typography>
			</Box>
		</Layout>
	);
};

export default Settings;
