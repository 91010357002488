import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import cepe from '../../pecahan/100000.jpg';
import gocap from '../../pecahan/50000.jpg';
import duapuluh from '../../pecahan/20000.jpg';
import ceban from '../../pecahan/10000.jpg';
import goceng from '../../pecahan/5000.jpg';
import duaribu from '../../pecahan/2000.jpg';
import seribu from '../../pecahan/1000.jpg';


type PecahanValue = {
  id: string;
  name: string;
  value: number;
  image: string;
  enabled: boolean;
  total: number | string;
}
type StateConfig = {
  [key: string]: PecahanValue;
}
type State = {
  pecahan: StateConfig;
}

const defaultTotal = 1;
const initialState: State = {
  pecahan: {
    "1": {
      id: "1",
      name: 'Rp. 100.000',
      value: 100000,
      image: cepe,
      enabled: true,
      total: defaultTotal
    },
    "2": {
      id: "2",
      name: 'Rp. 50.000',
      value: 50000,
      image: gocap,
      enabled: true,
      total: defaultTotal
    },
    "3": {
      id: "3",
      name: 'Rp. 20.000',
      value: 20000,
      image: duapuluh,
      enabled: true,
      total: defaultTotal
    },
    "4": {
      id: "4",
      name: 'Rp. 10.000',
      value: 10000,
      image: ceban,
      enabled: true,
      total: defaultTotal
    },
    "5": {
      id: "5",
      name: 'Rp. 5.000',
      value: 5000,
      image: goceng,
      enabled: true,
      total: defaultTotal
    },
    "6": {
      id: "6",
      name: 'Rp. 2.000',
      value: 2000,
      image: duaribu,
      enabled: true,
      total: defaultTotal
    },
    "7": {
      id: "7",
      name: 'Rp. 1.000',
      value: 1000,
      image: seribu,
      enabled: true,
      total: defaultTotal
    }
  }
};

// PayloadAction<{id:string; qty:number}>
const dataSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setPecahan: (state, action: PayloadAction<{ id: string; value: PecahanValue }>) => {
      const { id, value } = action.payload;
      state.pecahan[id] = value;
    },
    editPecahan: (state, action: PayloadAction<{ id: string; value: boolean }>) => {
      const { id, value } = action.payload;
      state.pecahan[id] = { ...state.pecahan[id], enabled: value };
    },
    resetConfig: (state) => {
      state.pecahan = initialState.pecahan;
    },
  },
  extraReducers: (builder) => {

  },
});

export const { setPecahan, editPecahan, resetConfig } = dataSlice.actions;
export default dataSlice.reducer;
