import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { CssBaseline, PaletteMode } from '@mui/material';
import * as React from 'react';
import GlobalStyles from './globalStyles';
import { amber, deepOrange, grey } from '@mui/material/colors';
import { useAppSelector, useAppDispatch } from '../config/redux/hooks';
import { setTheme } from '../config/redux/uiSlice';

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		order: true;
		side: true;
		trade: true;
		type: true;
	}
}

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const getDesignTokens = (mode: PaletteMode) => ({
	palette: {
		mode,
		...(mode === 'light'
			? {
					// palette values for light mode
					primary: {
						main: '#fafafa',
					},
					secondary: {
						main: '#26a27b',
					},
			  }
			: {
					// palette values for dark mode
					primary: {
						main: '#26a27b',
					},
					secondary: {
						main: '#fafafa',
					},
			  }),
	},
});

function ThemeCustomize(props: any) {
	const { children, ...other } = props;
	const dispatch = useAppDispatch();
	const ui = useAppSelector((state) => state.ui);
	const { theme } = ui;
	const colorMode = React.useMemo(
		() => ({
			toggleColorMode: () => {
				// setStorageTheme((prevMode: string) => (prevMode === 'light' ? 'dark' : 'light'));
				dispatch(setTheme(theme === 'light' ? 'dark' : 'light'));
			},
		}),
		[]
	);

	const darkModeTheme = createTheme(getDesignTokens(theme));

	const themeConfig = createTheme({
		palette: {
			mode: theme,
			...(theme === 'light'
				? {
						// palette values for light mode
						background: {
							paper: '#c8facd',
						},
						// background: {
						// 	paper: '#eeeeee',
						// },
				  }
				: {
						// palette values for dark mode
						background: {
							paper: '#212b36',
							// default: '#161c24',
						},
				  }),
		},
		components: {
			MuiButton: {
				variants: [
					{
						props: { variant: 'order', color: 'secondary' },
						style: {
							color: '#848e9c',
							backgroundColor: '#2b3139',
						},
					},
					{
						props: { variant: 'side', color: 'success' },
						style: {
							boxShadow: 'none',
							color: '#fff',
							backgroundColor: '#0ecb81',
						},
					},
				],
			},

			// MuiButton: {
			// 	styleOverrides: {
			// 		root: {
			// 			fontSize: '14px',
			// 			borderRadius: '8px',
			// 		},
			// 	},
			// },
			// MuiCard: {
			// 	styleOverrides: {
			// 		root: {
			// 			borderRadius: 2,
			// 			padding: '16px',
			// 			bgcolor: 'background.paper',
			// 		},
			// 	},
			// },
			// MuiCssBaseline: {
			// 	styleOverrides: {
			// 		'.boxHandler': {
			// 			borderRadius: 2,
			// 			p: '16px',
			// 			bgcolor: 'background.paper',
			// 		},
			// 	},
			// },
			// MuiTypography: {
			// 	styleOverrides: {
			// 		root: {
			// 			fontSize: '18px',
			// 		},
			// 	},
			// },
			// MuiTextField: {
			// 	styleOverrides: {
			// 		root: {
			// 			fontSize: '18px',
			// 		},
			// 	},
			// },
			// MuiInputLabel: {
			// 	styleOverrides: {
			// 		outlined: {
			// 			'&.MuiInputLabel-shrink': {
			// 				fontSize: '18px',
			// 			},
			// 		},
			// 		root: {
			// 			fontSize: '18px',
			// 		},
			// 	},
			// },
			// MuiOutlinedInput: {
			// 	styleOverrides: {
			// 		notchedOutline: {
			// 			fontSize: '18px',
			// 		},
			// 	},
			// },
			// MuiInputBase: {
			// 	styleOverrides: {
			// 		input: {
			// 			fontSize: '18px',
			// 		},
			// 	},
			// },
			// MuiSvgIcon: {
			// 	styleOverrides: {
			// 		root: {
			// 			fontSize: '24px',
			// 		},
			// 	},
			// },
			// MuiAutocomplete: {
			// 	styleOverrides: {
			// 		root: {
			// 			fontSize: '18px',
			// 		},
			// 		option: {
			// 			fontSize: '18px',
			// 		},
			// 	},
			// 	defaultProps: {
			// 		disablePortal: true,
			// 	},
			// },
			// MuiPopover: {
			// 	defaultProps: {
			// 		disablePortal: true,
			// 	},
			// },
			// MuiMenu: {
			// 	defaultProps: {
			// 		disablePortal: true,
			// 	},
			// },
			// MuiMenuItem: {
			// 	styleOverrides: {
			// 		root: {
			// 			fontSize: '18px',
			// 		},
			// 	},
			// },
			// MuiPaper: {
			// 	styleOverrides: {
			// 		root: {
			// 			fontSize: '18px',
			// 		},
			// 	},
			// },
			// MuiSelect: {
			// 	styleOverrides: {
			// 		outlined: {
			// 			'&.MuiInputLabel-shrink': {
			// 				fontSize: '18px',
			// 			},
			// 			// padding: '22.5px 14px 10.5px 14px !important',
			// 		},
			// 	},
			// 	defaultProps: {
			// 		MenuProps: {
			// 			disablePortal: true,
			// 		},
			// 	},
			// },
		},
	});

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={themeConfig}>
				<CssBaseline />
				<GlobalStyles />
				{children}
			</ThemeProvider>
		</ColorModeContext.Provider>
	);
}
export default ThemeCustomize;
