import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ShareIcon from '@mui/icons-material/Share';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import Drawer from './Drawer';
export default function ButtonAppBar() {
	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position='fixed'>
				<Toolbar>
					<Drawer />
					<Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
						THR ACAK
					</Typography>
					{/* <Button color='inherit'>Share</Button> */}
					<IconButton
						size='large'
						edge='end'
						color='inherit'
						onClick={() => {
							const text = [
								`*Yuk pakai THR ACAK untuk mengacak THR kamu!*`,
								`Dengan cara kunjungi website :`,
								`https://thr.bogorwebhost.com`,
							];

							const combineText = text.map((t: string) => `${encodeURIComponent(t)}%0A`).join('');
							const link = `https://wa.me/?text=${combineText}`;
							window.open(link);
						}}
					>
						<ShareIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
		</Box>
	);
}
