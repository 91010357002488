import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { Box, InputAdornment, Stack, Switch, Typography, TextField } from '@mui/material';
import NumberFormat from 'react-number-format';
import Avatar from '@mui/material/Avatar';
import Layout from '../templates/Layout';
import { useAppDispatch, useAppSelector } from '../../config/redux/hooks';
import { setPecahan, editPecahan } from '../../config/redux/configSlice';
import InputField from '../molecules/InputField';
import type { StateFormatNumber } from '../../types';

const Donate = () => {
	return (
		<>
			<Box
				sx={{
					bgcolor: 'background.paper',
					p: 2,
					mb: 1,
					borderRadius: 2,
				}}
			>
				<Typography variant='subtitle2' component='div' color='text.primary'>
					THR buat developernya dong :D
				</Typography>
			</Box>

			<Box
				sx={{
					bgcolor: 'background.paper',
					p: 2,
					mt: 1,
					borderRadius: 2,
				}}
			>
				<Stack spacing={1}>
					<Typography variant='subtitle2' component='div' color='text.primary' sx={{ mb: 2 }}>
						OVO | GOPAY | LINKAJA | DANA | SHOPEEPAY
					</Typography>

					<Typography variant='body2' component='div' color='text.primary'>
						Nomor : 0857-1729-2962
					</Typography>

					<Typography variant='body2' component='div' color='text.primary'>
						Atas Nama : Dede Sundara
					</Typography>
				</Stack>
			</Box>

			<Box
				sx={{
					bgcolor: 'background.paper',
					p: 2,
					mt: 1,
					borderRadius: 2,
				}}
			>
				<Stack spacing={1}>
					<Typography variant='subtitle2' component='div' color='text.primary' sx={{ mb: 2 }}>
						Transfer Bank BCA
					</Typography>

					<Typography variant='body2' component='div' color='text.primary'>
						Nomor Rekening : 6830260615
					</Typography>

					<Typography variant='body2' component='div' color='text.primary'>
						Atas Nama : Dede Sundara
					</Typography>
				</Stack>
			</Box>
		</>
	);
};

export default Donate;
