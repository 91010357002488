import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { InputAdornment, Stack, Switch, Typography, TextField, Toolbar } from '@mui/material';
import Home from '../pages/Home';
import Settings from '../pages/Settings';
import ThrDev from '../pages/ThrDev';
import Donate from '../pages/Donate';
import { useAppDispatch, useAppSelector } from '../../config/redux/hooks';
import { setView } from '../../config/redux/uiSlice';

const menuList = [
	{
		name: 'THR Acak',
		value: 'home',
		icon: <CurrencyExchangeIcon />,
	},
	{
		name: 'Settings',
		value: 'settings',
		icon: <SettingsIcon />,
	},
	{
		name: 'THR Developer',
		value: 'thrdev',
		icon: <MonetizationOnIcon />,
	},
];

export default function TemporaryDrawer() {
	const dispatch = useAppDispatch();
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const [open, setOpen] = React.useState(false);

	const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (
			event.type === 'keydown' &&
			((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
		) {
			return;
		}
		setOpen(open);
	};

	const list = () => (
		<Box sx={{ width: 250, mt: 2 }} role='presentation' onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
			<List>
				{menuList.map((item, index) => (
					<ListItem
						button
						key={index}
						onClick={() => {
							dispatch(setView(item.value));
							setOpen(false);
						}}
					>
						<ListItemIcon>{item.icon}</ListItemIcon>
						<ListItemText primary={item.name} />
					</ListItem>
				))}
				<Box sx={{ p: 1, mt: 3 }}>
					<Donate />
				</Box>
			</List>
		</Box>
	);

	return (
		<React.Fragment>
			<IconButton
				size='large'
				edge='start'
				color='inherit'
				aria-label='menu'
				sx={{ mr: 2 }}
				onClick={toggleDrawer(true)}
			>
				<MenuIcon />
			</IconButton>

			{/* <Button onClick={toggleDrawer(true)}>{anchor}</Button> */}
			<Drawer anchor={'left'} open={open} onClose={toggleDrawer(false)}>
				{list()}
			</Drawer>
		</React.Fragment>
	);
}
