import React from 'react';
import logo from './logo.svg';
// import './App.css';
import Layout from './components/templates/Layout';
import Settings from './components/pages/Settings';
import Home from './components/pages/Home';
import ThrDev from './components/pages/ThrDev';
import { useAppDispatch, useAppSelector } from './config/redux/hooks';

function App() {
	const dispatch = useAppDispatch();
	const { onView } = useAppSelector((state) => state.ui);

	const ChangeViews = () => {
		switch (onView) {
			case 'settings':
				return <Settings />;
			case 'home':
				return <Home />;
			case 'thrdev':
				return <ThrDev />;
			default:
				return <Settings />;
		}
	};

	return <React.Fragment>{ChangeViews()}</React.Fragment>;
}

export default App;
