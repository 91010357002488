import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store'

export type PaletteMode = 'light' | 'dark';

export type UIType = {
  theme: PaletteMode;
  onView: string;
  alertNotice: AlertView
}
export type AlertView = {
  show: boolean;
  message: string;
  type: 'success' | 'info' | 'warning' | 'error';
}

const initialState: UIType = {
  theme: 'dark',
  onView: 'settings',
  alertNotice: {
    show: false,
    message: '',
    type: 'success'
  },
};

const name = 'ui';
const stateSecond = createSlice({
  name,
  initialState,
  reducers: {
    setUI: (state, action: PayloadAction<UIType>) => {
      state = action.payload;
    },
    setTheme: (state, action: PayloadAction<PaletteMode>) => {
      state.theme = action.payload;
    },
    setView: (state, action: PayloadAction<string>) => {
      state.onView = action.payload;
    },
  },
});

export default stateSecond.reducer;
export const selectUI = (state: RootState) => state.ui;
export const { setUI, setTheme, setView } = stateSecond.actions;
