import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
// import { InputAdornment} from '@mui/material';
import type { TextFieldProps } from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { memo } from 'react';
import type { NumberFormatProps } from 'react-number-format';

type StateFormatNumber = {
	formattedValue: string;
	value: string | number;
	floatValue: number;
};

type InputTradeProps = {
	value: string | number;
	sideValue: string | number;
	label?: string;
	onChange: (event: StateFormatNumber) => void;
	readOnly?: boolean;
	helperText?: string | number | React.ReactNode;
	disabled?: boolean;
	decimalScale?: number;
	size?: 'small' | 'medium';
	error?: boolean;
};

function FormattedInputs(props: InputTradeProps) {
	const { value, sideValue, label, onChange, readOnly, helperText, disabled, decimalScale, error } = props;
	const getSide = sideValue.toString().split(' ');
	const targetSide = getSide[1] ? getSide[0] + '  ' + getSide[1].toUpperCase() : getSide[0].toUpperCase();

	const [state, setState] = useState<StateFormatNumber>({
		formattedValue: '',
		value: value.toString(),
		floatValue: 0,
	});

	let floatValue: number | undefined = 0;
	return (
		<Tooltip open={false} title={helperText ? helperText : ''} placement='top-end' arrow>
			<FormControl variant='outlined' fullWidth sx={{ mt: 1, width: '100%' }}>
				{/* <InputLabel className='mText'>{label}</InputLabel> */}
				<NumberFormat
					thousandsGroupStyle='thousand'
					customInput={TextField}
					disabled={disabled}
					variant='outlined'
					value={value}
					// defaultValue={value}
					// label={label}
					// name={label}
					InputLabelProps={{ shrink: true }}
					autoComplete='off'
					size={props.size ? props.size : 'medium'}
					// thousandSeparator={true}
					decimalScale={decimalScale ? decimalScale : 0}
					thousandSeparator='.'
					decimalSeparator=','
					// onChange={onChange}
					inputProps={{
						inputMode: 'numeric',
						pattern: '/^-?d+(?:.d+)?$/g',
					}}
					InputProps={{
						readOnly: readOnly,
						endAdornment: <InputAdornment position='end'>{sideValue.toString().toUpperCase()}</InputAdornment>,
						autoComplete: 'off',
						// inputComponent: NumberFormatCustom as any,
					}}
					error={Number(value) === 0 && !disabled}
					helperText={helperText}
					onValueChange={(values) => (floatValue = values.floatValue)}
					onChange={() => {
						onChange({ ...state, value: floatValue || '', floatValue: Number(floatValue) });
					}}
				/>
			</FormControl>
		</Tooltip>
	);
}

// {
// 	"formattedValue": "12.222",
// 	"value": "12222",
// 	"floatValue": 12222
// }
export default FormattedInputs;
