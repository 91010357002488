import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { Box, InputAdornment, Stack, Switch, Typography, TextField } from '@mui/material';
import NumberFormat from 'react-number-format';
import Avatar from '@mui/material/Avatar';
import Layout from '../templates/Layout';
import { useAppDispatch, useAppSelector } from '../../config/redux/hooks';
import { setPecahan, editPecahan } from '../../config/redux/configSlice';
import InputField from '../molecules/InputField';
import type { StateFormatNumber } from '../../types';
import Donate from './Donate';

const ThrDev = () => {
	return (
		<Layout>
			<Donate />
		</Layout>
	);
};

export default ThrDev;
