import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Header from '../organisms/Header';
import Footer from '../organisms/Footer';

type Props = {
	children: React.ReactNode;
};
const Layout = (props: Props) => {
	const { children } = props;
	return (
		<React.Fragment>
			<Header />
			<Toolbar />
			<Paper square sx={{ p: 2, minHeight: '100%' }}>
				{children}
			</Paper>
		</React.Fragment>
	);
};

export default Layout;
