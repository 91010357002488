import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { Box, InputAdornment, Stack, Switch, Typography, TextField } from '@mui/material';
import NumberFormat from 'react-number-format';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Skeleton from '@mui/material/Skeleton';
import Fab from '@mui/material/Fab';
import Layout from '../templates/Layout';
import Footer from '../organisms/Footer';
import { useAppDispatch, useAppSelector } from '../../config/redux/hooks';
import { setPecahan, editPecahan } from '../../config/redux/configSlice';
import InputField from '../molecules/InputField';
import type { StateFormatNumber } from '../../types';

function getRandomInt(min: number, max: number) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

const HomeCheck = () => {
	const { pecahan } = useAppSelector((state) => state.config);
	const enabledThr = Object.values(pecahan).filter((item) => item.enabled === true);
	// const [isLoading, setIsLoading] = useState(true);

	// const cacheImages = async (srcArray: string[]) => {
	// 	const promises = await srcArray.map((src) => {
	// 		return new Promise((resolve, reject) => {
	// 			const img = new Image();
	// 			img.onload = () => resolve(src);
	// 			img.onerror = () => reject(src);
	// 			img.src = src;
	// 		});
	// 	});

	// 	await Promise.all(promises);
	// 	setIsLoading(false);
	// };

	// useEffect(() => {
	// 	const srcArray = Object.values(pecahan).map((item) => item.image);
	// 	cacheImages(srcArray);
	// }, []);

	return (
		<Layout>
			{enabledThr.length > 0 ? (
				<Home />
			) : (
				<Typography variant='body1' component='div' color='text.primary'>
					Tidak ada THR
				</Typography>
			)}
		</Layout>
	);
};

const Home = () => {
	const dispatch = useAppDispatch();
	const { pecahan } = useAppSelector((state) => state.config);
	const enabledValue = Object.values(pecahan).filter((item) => item.enabled === true);
	const enabledThr = enabledValue.filter((item) => Number(item.total) > 0);

	// const activeThr = enabledThr.filter((item) => Number(item.total) > 0);

	const intervalRandom = useRef<number | undefined>(undefined);
	const timeoutRandom = useRef<number | undefined>(undefined);
	const [value, setValue] = useState(enabledThr[0]);
	const [onRandom, setOnRandom] = useState(false);
	const [onDone, setOnDone] = useState(false);
	const [first, setFirst] = useState(true);

	// const StyledFab = styled(Fab)({
	// 	position: 'absolute',
	// 	zIndex: 1,
	// 	top: -30,
	// 	left: 0,
	// 	right: 0,
	// 	margin: '0 auto',
	// });

	const StyledFab = styled(Fab)({
		position: 'absolute',
		zIndex: 1,
		top: -70,
		left: 0,
		right: 0,
		margin: '0 auto',
		width: '100px',
		height: '100px',
	});

	useEffect(() => {
		if (!intervalRandom.current && enabledThr.length && onRandom) {
			intervalRandom.current = window.setInterval(() => {
				const targetArray = enabledThr.length > 1 ? enabledThr.length - 1 : 0;
				const getRandom = getRandomInt(0, targetArray);
				setValue(enabledThr[getRandom]);
				// setRandom(getRandom);
			}, 100);
			if (intervalRandom.current && !timeoutRandom.current) {
				timeoutRandom.current = window.setTimeout(() => {
					window.clearInterval(intervalRandom.current);
					window.clearTimeout(timeoutRandom.current);
					intervalRandom.current = undefined;
					timeoutRandom.current = undefined;
					setOnRandom(false);
					setOnDone(true);
				}, 5000);
			}
		}
	}, [onRandom]);

	useEffect(() => {
		if (onDone) {
			if (first) {
				setFirst(false);
			}
			dispatch(
				setPecahan({
					id: value.id,
					value: { ...value, total: Number(value.total) - 1 },
				})
			);
		}
	}, [onDone]);

	useEffect(() => {
		return () => {
			window.clearTimeout(timeoutRandom.current);
			window.clearInterval(intervalRandom.current);
		};
	}, []);
	const arraySrcs = enabledValue.map((item) => item.image);
	return (
		<>
			{value ? (
				<Stack sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
					{/* <img src={value.image} alt='' /> */}
					{arraySrcs.map((e, i) => (
						<img key={i} src={e} style={{ display: value.image === e ? 'block' : 'none' }} />
					))}
					{!onRandom && !first ? (
						<Box
							sx={{
								bgcolor: 'background.paper',
								p: 2,
								mb: 1,
								mt: 3,
								borderRadius: 2,
								width: '100%',
							}}
						>
							<Typography variant='h6' component='div' color='text.primary'>
								THR kamu {value.name}
							</Typography>
						</Box>
					) : null}
				</Stack>
			) : null}

			<React.Fragment>
				<AppBar position='fixed' color='primary' sx={{ top: 'auto', bottom: 0 }}>
					<Toolbar>
						<StyledFab
							color='success'
							aria-label='add'
							disabled={!enabledThr.length || onRandom}
							onClick={() => {
								if (enabledThr.length) {
									setOnRandom(true);
									setOnDone(false);
								}
							}}
						>
							<Typography variant='h5' component='div' color='text.primary' sx={{ fontWeight: 'bold' }}>
								ACAK
							</Typography>
						</StyledFab>
						<Box sx={{ flexGrow: 1 }} />
					</Toolbar>
				</AppBar>
			</React.Fragment>
		</>
	);
};

export default HomeCheck;
